<template>
  <div class="contents liveInfo">
    <div class="tabBox recode" >
      <ul class="tabSecond2 flex">
        <li :class="{ active: visible == showType[0] ? true : false }">
          <a class="point medium" @click="handleTab(showType[0])" style="width: auto;">
            {{ $t("site-monitoring-station-tab-list")}}
          </a>
        </li>
        <li :class="{ active: visible == showType[1] ? true : false }">
          <a class="medium" @click="handleTab(showType[1])">
            {{ $t("site-monitoring-station-tab-log")}}
          </a>
        </li>
      </ul>
      <div class="contents" v-if="visible == showType[0]" style="padding:0;">
        <div class="one">
          <div class="boxCol2-2 flexB" >
            <div class="tableWrap box" style="width: calc(70% - 2px); height: 100%; min-height: 523px;">
              <div class="tableBox">
                <div style="min-height: 453px;">
                  <table>
                    <!--                    <colgroup>
                                          <col style="width: 5%;"/>
                                          <col style="width: 15%;"/>
                                          <col style="width: 30%;"/>
                                          <col style="width: 25%;"/>
                                          <col style="width: 15%;"/>
                                          <col style="width: 10%;"/>
                                        </colgroup>-->
                    <tr>
                      <th>No</th>
                      <th>{{ $t("site-monitoring-station-device") }}</th>
                      <th>{{ $t("site-monitoring-station-station") }}</th>
                      <th>{{ $t("site-monitoring-station-manager") }}</th>
                      <th>{{ $t("site-monitoring-station-dept") }}</th>
                      <th>{{ $t("site-monitoring-station-phone") }}</th>
                      <th>{{ $t("site-monitoring-station-operation-num") }}</th>
                    </tr>
                    <tr v-if="visibleTotal == 0">
                      <td colspan="6" style="text-align: center;">
                        {{ $t("search-not-found") }}
                      </td>
                    </tr>
                    <tr v-for="(data, i) in visibleList" :key="i" @click="handleSelectEl(data, i)" :class="{ active: (selectData != null && joinSelectId == (data.deviceId +  data.userId))}">
                      <td>{{ visibleTotal - (size * currentPage + i) }}</td>
                      <td>{{data.deviceName}}</td>
                      <td>{{data.userDepartmentName}}</td>
                      <td>{{data.userName}}</td>
                      <td>{{getMask(data.userPhone)}}</td>
                      <td>{{data.joinCount}}</td>
                      <td>{{data.joinCount}}</td>
                    </tr>
                  </table>
                </div>
                <div class="pagination">
                  <el-pagination
                      small
                      layout="prev, pager, next"
                      :total="visibleTotal"
                      :page-size="size"
                      @current-change="handleCurrentChange"
                      :current-page="currentPage + 1"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
            <div class="box infoWrap" style="width: calc(30% - 2px); height: 100%; min-height: 523px;">
              <h2 class="bold">
                {{ $t("title-detail-info") }}
              </h2>
              <table>
                <colgroup>
                  <col style="width: 25%;"/>
                  <col style="width: 75%;"/>
                </colgroup>
                <tr>
                  <td>
                    <p class="bold">{{ $t("site-monitoring-station-device") }}</p>
                  </td>
                  <td>{{ selectData != null ? selectData.deviceName : '-' }}</td>
                </tr>
                <tr>
                  <td>
                    <p class="bold">{{ $t("site-monitoring-station-station") }}</p>
                  </td>
                  <td>{{ selectData != null ? selectData.deviceName : '-' }}</td>
                </tr>
                <tr>
                  <td>
                    <p class="bold">{{ $t("site-monitoring-station-manager") }}</p>
                  </td>
                  <td>{{ selectData != null ? selectData.userName : '-' }}</td>
                </tr>
                <tr>
                  <td><p class="bold">{{ $t("site-monitoring-station-dept") }}</p></td>
                  <td>{{ selectData != null ? selectData.ownerDepartmentInfo.name : '-' }}</td>
                </tr>
                <tr>
                  <td>
                    <p class="bold">{{ $t("site-monitoring-station-phone") }}</p>
                  </td>
                  <td>{{ selectData != null || selectData != undefined ? selectData.ownerDepartmentInfo.phone : '-' }}</td>
                </tr>
                <tr>
                  <td><p class="bold">{{ $t("site-monitoring-station-operation-num") }}</p></td>
                  <td>{{ selectData != null ? selectData.joinCount : '-' }}</td>
                </tr>
                <!--                <tr>
                                  <td><p class="bold">{{ $t("device-data-working-last-flight-time") }}</p></td>
                                  <td>{{ selectData != null ? moment(selectData.endTime).format("YYYY-MM-DD HH:mm") : '-' }}</td>
                                </tr>-->
                <tr>
                  <td colspan="2" style="text-align: center; border: none;">
                    <div v-if="selectData != null" >
                      <img :src="getDeviceImgUrl(selectData.accountId, selectData.deviceId)" alt="썸네일" class="deviceImg"
                           style="width: auto; height: auto; max-height: 168px; border: none; margin: auto; padding:15px; "/>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="box one filter">
          <div class="flex">
            <p class="bold">{{ $t("search-keyword-title") }}</p>
            <select v-model="keywordType">
              <option value="eventControl">{{ $t("search-keyword-all") }}</option>
              <option value="deviceName">{{ $t("device-data-device-name") }}</option>
              <option value="departmentName">{{ $t("device-data-manager-dept-name") }}</option>
              <option value="userName">{{ $t("device-data-manager-operator") }}</option>
            </select>
            <input
                type="text"
                v-model="keyword"
                @keydown.enter="joinSearch"
            />
            <div class="buttonWrap" style="display: flex; margin-top: 0;">
              <button class="point medium" @click="joinSearch" style="margin-left: 20px; width: 120px;">
                {{ $t("btn-searching") }}
              </button>
              <button class="medium margin6" @click="reset" style="width: 120px;">{{ $t("btn-reset") }}</button>
            </div>
          </div>
          <!--          <div class="flex">
                      <p class="bold">{{ $t("device-data-start-flight-time") }}</p>
                      <Datepicker
                          v-model="startYmd"
                          :language="ko"
                          :format="customFormatter"
                          placeholder="YYYY-MM-DD"
                      ></Datepicker>
                      <span>~</span>
                      <Datepicker
                          v-model="endYmd"
                          :language="ko"
                          :format="customFormatter"
                          placeholder="YYYY-MM-DD"
                      ></Datepicker>
                    </div>-->
        </div>
      </div>
      <div class="contents" v-if="visible == showType[1]" style="padding:0;">
        <div class="one">
          <div class="boxCol2-2 flexB">
            <div class="tableWrap box" style="width: calc(50% - 2px); height: 100%; min-width: 780px; min-height: 523px;">
              <div class="tableBox">
                <div style="min-height: 453px;">
                  <table>
                    <tr>
                      <th>No</th>
                      <th>{{ $t("site-monitoring-station-start-time") }}</th>
                      <th>{{ $t("site-monitoring-station-end-time") }}</th>
                      <th>{{ $t("site-monitoring-station-operation-time") }}</th>
                      <th>{{ $t("site-monitoring-station-drone-name") }}</th>
                      <th>{{ $t("site-monitoring-station-station-name") }}</th>
                    </tr>
                    <tr v-if="visibleTotal == 0">
                      <td colspan="7" style="text-align: center;">
                        {{ $t("search-not-found") }}
                      </td>
                    </tr>
                    <tr v-for="(data, i) in visibleList" :key="i" @click="handleSelectEl(data, i)" :class="{ active: data.deviceLogId == (selectData != null && selectData.deviceLogId) }">
                      <td>{{ visibleTotal - (size * currentPage + i) }}</td>
                      <td>
                        {{ moment(data.startTime).format("YYYY-MM-DD HH:mm") }}
                      </td>
                      <td>
                        {{ moment(data.endTime).format("YYYY-MM-DD HH:mm")  }}
                      </td>
                      <td>
                        {{ logTimeToJoinTime(data.startTime, data.endTime) }}
                      </td>
                      <td>
                        {{ data.deviceName}}
                      </td>
                      <td>
                        선박용 드론 스테이션
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="pagination">
                  <el-pagination
                      small
                      layout="prev, pager, next"
                      :total="visibleTotal"
                      :page-size='size'
                      @current-change="handleCurrentChange"
                      :current-page="currentPage + 1"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
            <div class="box" style="width: calc(50% - 2px); height: 100%; min-height: 523px;">
              <h2 class="bold">{{ $t("site-view-data-location-Player") }}</h2>
              <div style="position: relative">
                <div v-if="locationSize == 0" style="z-index:4; margin-top: 25%; top: 20%; font-size: 2em; right: 25%; left: 25%; line-height: 20; position: absolute; color: white; text-align: center; background-color: rgba(30, 30, 30, 0.8);">
                  {{$t("popup-location-not-found")}}
                </div>
              </div>

              <div style="display: flex; width: 100%; ">
                <div style="width: calc(80% - 2px);">
                  <div class="mapWrap" style="width: 100%; min-width: 480px;">
                    <div class="map" style="min-height: 380px;">
                      <Map style="width: 100%; height: 100%; position: absolute;" ref="map" isRightLngLat="true" :loadMapName="loadMap" @play-marker-lng-lat="playMarkerLngLat"/>
                    </div>
                  </div>
                  <div class="deviceInfo" style="width: 100%; min-width: 480px;">
                    <div class="progress-bar">
                      <input style="width: 100%; height: 3px;" type="range" id="volume" name="volume" min="0" v-model="progressbarValue" :max="progressbarTotal">
                      <img :src="!isMapPlay ? require('@/assets/images/icon_svg/ic-play.svg') : require('@/assets/images/icon_svg/ic-pause.svg')" alt="icon" @click="onMapPlay">
                      <span class="first">{{ secConvert(progressbarValue) }}</span>
                      <span class="first">/</span>
                      <span class="end">{{ secConvert(progressbarTotal) }}</span>
                    </div>
                  </div>
                </div>

                <div style="background: #f4f4f4; min-width: 150px; display:flex; flex-wrap: wrap; flex-direction: column;">
                <span class="bold"
                      style="border: 1px solid #d3d3d3; width: 125px; padding: 10px 0; margin: 30px auto 0; text-align: center;">스테이션 상태정보</span>
                  <div style="margin: 0 auto;">
                    <div class="stationRow">
                      <span class="bold">전원</span>
                      <span class="stationData">정상</span>
                    </div>
                    <div class="stationRow">
                      <span class="bold">상태</span>
                      <span class="stationData">초기화중</span>
                    </div>
                    <div class="stationRow">
                      <span class="bold">풍향</span>
                      <span class="stationData">남동풍</span>
                    </div>
                    <div class="stationRow">
                      <span class="bold">풍속</span>
                      <span class="stationData">10m/s</span>
                    </div>
                    <div class="stationRow">
                      <span class="bold">온도</span>
                      <span class="stationData">24.5℃</span>
                    </div>
                    <div class="stationRow">
                      <span class="bold">습도</span>
                      <span class="stationData">67%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box one filter">
          <div class="flex">
            <p class="bold">{{ $t("search-keyword-title") }}</p>
            <select v-model="keywordType">
              <option value="eventControl">{{ $t("search-keyword-all") }}</option>
              <option value="deviceName">{{ $t("device-data-device-name") }}</option>
              <option value="departmentName">{{ $t("device-data-manager-dept-name") }}</option>
              <option value="userName">{{ $t("device-data-manager-operator") }}</option>
            </select>
            <input
                type="text"
                v-model="keyword"
                @keydown.enter="logSearch"
            />
            <div class="buttonWrap" style="display: flex; margin-top: 0;">
              <button class="point medium" @click="logSearch" style="margin-left: 20px; width: 120px;">
                {{ $t("btn-searching") }}
              </button>
              <button class="medium margin6" @click="reset" style="width: 120px;">{{ $t("btn-reset") }}</button>
            </div>
          </div>
          <div class="flex">
            <p class="bold">{{ $t("device-data-start-flight-time") }}</p>
            <Datepicker
                v-model="startYmd"
                :language="ko"
                :format="customFormatter"
                placeholder="YYYY-MM-DD"
            ></Datepicker>
            <span>~</span>
            <Datepicker
                v-model="endYmd"
                :language="ko"
                :format="customFormatter"
                placeholder="YYYY-MM-DD"
            ></Datepicker>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.progress-bar {
  width: 100%;
  height: 50px;
  position: relative;
  bottom: 0;
  margin: -61px 0 0;
  padding: 3.8px 19.8px 3px 18.6px;
  background-color: rgba(30, 30, 30, 0.5);
  text-align: right;
}
.progress-bar > img {
  margin-top: 4px;
  float: left;
}
.progress-bar > div {
  display: contents;
}
.progress-bar > span.first {
  display:inline-block;
  padding-top: 4px;
  color : white;
  margin-right: 10px;
}
.progress-bar > span.end {
  display:inline-block;
  padding-top: 4px;
  margin-top: 4px;
  color : white;
}
.stationData{
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.2rem;
}
.stationRow{
  margin-top: 15px;
}
.stationRow>span{
  font-size: 1.2rem;
}
</style>
<script>
import moment from "moment";
import Map from "@/components/map/Map";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";

import {fetchEventJoinList} from "@/api/dashboard";
import { fetchEvent } from "@/api/event";
import {fetchDeviceLogList, fetchDeviceDetail} from "@/api/device";
import { fetchPointList, getPointTypeName } from "@/api/point";
import {getDeviceImgUrl} from "@/api/device";

//import {fetchWorkerLogList} from "@/api/worker";
import { fetchLocation } from "@/api/location";
import { format } from "@/mixins/format";

import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

import { baseUrl } from "@/config/env";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";
export default {
  name: "Home",
  components: { Map, Datepicker },
  mixins: [format],
  props: {
    eventId: {
      type: String,
      default: "",
    },
    address: {
      type: String,
      default: "",
    },
  },
  watch: {
    currentLang(value){
      this.initMapData(value);
    },
    progressbarValue : function(value){
      this.$refs.map.onPlayUpdatePosition(value);
    },
    isMapPlay : function(value){
      if(value) {
        this.interval = setInterval(() => {
          if(this.progressbarValue == this.progressbarTotal) {
            clearInterval(this.interval);
            this.interval = null;
            return;
          }
          this.progressbarValue =  Number(this.progressbarValue) + 1;
        }, 1000);
      } else {
        if(this.interval != null) {
          clearInterval(this.interval);
        }
      }
    },
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 14 });
  },
  data() {
    return {
      moment: moment,
      isLoading:false,
      visible: "JOIN",
      showType: ["JOIN", "LOG"],
      eventInfo: null,
      baseUrl: baseUrl,
      loadMap : 'kakao',

      interval: null,
      selectEl: null,
      ko: ko,

      visibleList: [],
      visibleTotal : 0,
      selectData : null,

      joinSelectId : null,

      //이동기록 변수
      progressbarTotal: 0,
      progressbarValue : 0,
      isMapPlay : false,
      selectMemo : null,
      locationSize : null,

      //검색 조건
      startYmd: null,
      endYmd: null,
      size: 10,
      currentPage: 0,
      keywordType: "eventControl",
      statuses: null,
      keyword: null,

    };
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  async mounted() {
    this.getEvent();
    this.handleTab(this.showType[0]);
  },
  methods: {
    playMarkerLngLat(lng,lat) {
      this.$refs.map.onSetLngLat(lng,lat);
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },

    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function () {
        this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
        this.$refs.map.onShowButtonTypeChange();
        this.$refs.map.onShowLngLat();
        if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
          this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
          this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
        } else {
          this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
        }

        this.getEventPoint();

        if(this.selectData != null) {
          var dateFrom = this.selectData.checkInDt
          var dateTo = this.selectData.checkOutDt
          this.getLocation(this.selectData, dateFrom, dateTo);
        }
      })
    },

    getDeviceImgUrl(accountId, deviceId) {
      return getDeviceImgUrl(accountId, deviceId);
    },

    async getEventPoint() {
      let params = {
        eventId : this.eventId,
        serverTime : null,
      };
      await fetchPointList( params).then((res) => {
        if (res.data.result == 0) {
          this.pointServerTime = res.data.data.serverTime
          this.pointCount = res.data.data.total
          this.pointList = res.data.data.content
          this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = false;
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
          })
        }
      });
    },

    handleCurrentChange(val) {
      this.currentPage = val - 1;
      if(this.showType[0] == this.visible) {
        this.joinHandleTab();
      } else {
        this.logHandleTab();
      }
    },

    reset() {
      this.endYmd = null;
      this.startYmd = null;
      this.keyword = null;
      this.keywordType = "eventControl";
    },

    handleTab(id) {
      this.visible = id;
      this.visibleList.splice(0,1)  ;
      this.selectData = null;
      this.currentPage = 0;
      this.joinSelectId = null;

      if(this.showType[0] == id) {
        this.joinHandleTab();
      } else {
        this.initMapData(this.currentLang);
        this.logHandleTab();
      }
    },
    joinHandleTab(){
      this.getEventJoinDeviceList()
    },
    async logHandleTab() {
      this.isLoading = true;
      this.isMapPlay = false;
      await this.getDeviceLogList();
    },

    handleSelectEl(data, i) {
      if(this.showType[0] == this.visible) {
        this.joinHandleSelectEl(data, i);
      } else {
        this.logHandleSelectEl(data, i);
      }
    },
    joinHandleSelectEl(data){
      if(this.joinSelectId != null && this.joinSelectId == data.userId) {
        this.selectData = null;
        this.joinSelectId = null;
        return;
      }

      this.joinSelectId = data.deviceId + data.userId;
      fetchDeviceDetail(data.deviceId).then((res) => {
        if (res.data.result == 0) {
          this.selectData = res.data.data
          this.selectData.joinCount = data.joinCount
          this.selectData.userName = data.userName
          this.selectData.endTime = data.endTime
        }
      });
    },
    logHandleSelectEl(data, i){
      this.isMapPlay = false;

      this.$refs.map.onClearMapData();
      this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
      this.getEventPoint();

      var id = data.deviceLogId + "_" + i;
      if(this.selectEl == id) {
        this.selectEl = null;
        this.selectData = null;
      } else {
        this.selectEl = id;
        this.selectData = data;
      }
      if(this.visible != this.showType[0] && this.selectEl != null){
        var dateFrom = data.startTime
        var dateTo = data.endTime
        this.getLocation(this.selectData, dateFrom, dateTo);
      } else {
        this.getLocation(this.selectData);
      }
    },

    getEventJoinDeviceList(){
      let params = {
        eventId : this.eventId,
        pageSize : this.size,
        pageNumber : this.currentPage,
        keyword : this.keyword,
        keywordType : this.keywordType,
      };
      fetchEventJoinList('device', params).then((res) => {
        if (res.data.result == 0) {
          this.visibleList = res.data.data.content
          this.visibleTotal = res.data.data.total
        }
      });
    },
    joinSearch(){
      this.currentPage = 0;
      this.getEventJoinDeviceList();
    },
    async getDeviceLogList(){
      let params = {
        eventId : this.eventId,
        pageSize : this.size,
        pageNumber : this.currentPage,
        keyword : this.keyword,
        keywordType : this.keywordType,
        isStartTime : true,
        isEndTime : true,
      };
      if(this.startYmd != null && this.endYmd != null) {
        params["startDateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["startDateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      fetchDeviceLogList(params).then((res) => {
        if (res.data.result == 0) {
          this.visibleList = res.data.data.content
          this.visibleTotal = res.data.data.total
        }
      });
    },
    logSearch(){
      this.currentPage = 0;
      this.getDeviceLogList();
    },

    logTimeToJoinTime(checkInDt, checkOutDt){
      var inDate = new Date(moment(checkInDt));
      var outDate = new Date(moment(checkOutDt));

      var time = outDate.getTime() - inDate.getTime();
      if(time < 0){ //time 이 음수일 때 발생하는 오류 방지
        time = 0;
      }
      return this.secConvert(Math.floor(time/ 1000));
    },
    secConvert(seconds) {
      var hour = parseInt(seconds/3600) < 10 ? '0'+ parseInt(seconds/3600) : parseInt(seconds/3600);
      var min = parseInt((seconds%3600)/60) < 10 ? '0'+ parseInt((seconds%3600)/60) : parseInt((seconds%3600)/60);
      var sec = seconds % 60 < 10 ? '0'+seconds % 60 : seconds % 60;
      return hour+":"+min+":" + sec;
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
        }
      });
    },
    onMapPlay(){
      this.isMapPlay = ! this.isMapPlay;
    },
    async getLocation(data, dateFrom, dateTo) {
      this.progressbarTotal = 0;
      this.progressbarValue = 0;
      this.locationSize = null;
      if(data == null) {
        return;
      }

      let params = {
        refId: data.deviceLogId,
        eventId : this.eventId,
        'localDateRange.from' : null,
        'localDateRange.to' : null,
      };

      if(dateFrom != undefined && dateTo != undefined) {
        params['localDateRange.from'] = moment(dateFrom).format("YYYY-MM-DDTHH:mm:ss");
        params['localDateRange.to'] = moment(dateTo).format("YYYY-MM-DDTHH:mm:ss.SSS");
      }

      fetchLocation(params).then((res) => {
        if(res.data.result == 0) {
          this.locationSize = res.data.data.content.length;
          if(res.data.data.content.length > 0) {
            res.data.data.content.sort(function(a, b) {
              return a.index - b.index;
            })
            var first = new Date(moment(res.data.data.content[0].time));
            var last = new Date(moment(res.data.data.content[res.data.data.content.length - 1].time));
            var maxValue = last.getTime() - first.getTime();
            this.progressbarTotal = maxValue/ 1000;

            var firstData = res.data.data.content[0];
            this.$refs.map.onLngLatToMoveCenter(firstData.lng, firstData.lat)
            var mapLineData = new MapLineData();
            mapLineData.id = this.contentsId;
            mapLineData.locationGroup = res.data.data.content;
            this.$refs.map.onPlayCreateData(mapLineData);
          }
        } else {
          this.locationSize = 0;
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
